
// import logo from './logo.svg';

import { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js'
import AnButton from './AnButton'
// import LongPressButton from './LongPressButton';
import './App.css';


// Create a single supabase client for interacting with your database
// const supabaseUrl = 'https://dxntdoazjimsfallfhac.supabase.co'
// const supabaseKey = process.env.SUPABASE_KEY
// const supabase = createClient(supabaseUrl, supabaseKey)
const supabase = createClient(
    'https://dxntdoazjimsfallfhac.supabase.co',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR4bnRkb2F6amltc2ZhbGxmaGFjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMwMTg0MzUsImV4cCI6MjA0ODU5NDQzNX0.80PCt5jWF8t_7BKtNJiT8YFiVtc1bRuXxtL2rGwbG1c')


function App() {
    
    const [buttons, setButtons] = useState([])

    const fetchButtons = async () => {
        const { data, error } = await supabase
            .from('active_buttons')
            .select('*')
    
        if (error) {
            console.log('error', error)
            return
        }
        console.log('data', data)
        setButtons(data)
    }

    const [userId, setUserId] = useState(null)

    useEffect(() => {
        // 컴포넌트가 마운트될 때 실행됨
        console.log('mounted');

        const storedUserId = localStorage.getItem('an_user_id');
        console.log('storedUserId', storedUserId)
        setUserId(storedUserId); // useState로 상태 업데이트
        if (!storedUserId) {
            createAccessLog();
        }

        // 버튼 목록 받기
        fetchButtons();
        
        // 선택적: 언마운트 시 정리(clean-up) 함수
        return () => {
            console.log('unmounted');
        };
    }, []); // 빈 배열을 넣으면 마운트 시에만 실행, 변수를 넣으면 해당 변수가 변경될 때마다 실행


    const createAccessLog = async () => {
        const { data, error } = await supabase.rpc('create_access_log')

        if (error) {
            console.error('Error creating access log:', error)
        }
        else {
            const userId = data  // This is the UUID you can use for future requests
            console.log('Access log created:', userId)
            localStorage.setItem('an_user_id', userId)
        }
    }


    const saveInputLog = async (optionId) => {
        const reqBody = {
            user_id: userId,  // 이전에 받은 user_id
            log_type: 'input',
            option_id: optionId  // 선택한 옵션의 ID (버튼 아이디)
        }
        const { data, error } = await supabase
            .from('UserLog')
            .insert(reqBody)

        if (error) {
            console.error('Error saving input log:', error)
        }
        else {
            console.log('Input log saved:', data)
        }
    }


    const handleClick = (buttonId) => {
        console.log('click', buttonId)
        saveInputLog(buttonId)
        // createAccessLog()
    }

    return (
        <div className="btn-group">
            {buttons.map((button, idx) => (
                <AnButton key={idx} text={button.text} onClick={() => handleClick(button.id)} />
            ))}
        </div>



    // <div className="App">
    //   <header className="App-header">
        
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>Edit <code>src/App.js</code> and save to reload.</p>

    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>

    //   </header>
    // </div>
    );
}

export default App;
