
import React, { useState, useEffect } from 'react';
import styles from './AnButton.module.css';

function AnButton({text, onClick}) {
    const PRESS_DELAY = 1500;

    const [isPressed, setIsPressed] = useState(false);
    const [progress, setProgress] = useState(0);
    // const [message, setMessage] = useState('');
  
    // easeOut 함수: 처음엔 빠르고 나중엔 느려지는 효과
    const easeOut = (x) => {
        return 1 - Math.pow(1 - x, 5);  // 지수를 높이면 더 빨리 느려짐
    };

    useEffect(() => {
        let timer;
        let startTime;
    
        if (isPressed) {
            startTime = Date.now();
            timer = setInterval(() => {
                const elapsed = Date.now() - startTime;
                // const newProgress = Math.min((elapsed / PRESS_DELAY) * 100, 100);
                const linearProgress = Math.min(elapsed / 3000, 1);
                const easedProgress = easeOut(linearProgress) * 100;
                setProgress(easedProgress);
        
                if (elapsed >= PRESS_DELAY) {
                    // setMessage('버튼이 활성화되었습니다!');
                    onClick();
                    setIsPressed(false);
                    clearInterval(timer);
                    // alert('감사합니다!');
                }
            }, 16); // ~60fps for smooth animation
        } else {
            setProgress(0);
        }
    
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [isPressed]);
  
    const handleMouseDown = () => {
        setIsPressed(true);
        // setMessage('');
    };
  
    const handleMouseUp = () => {
        setIsPressed(false);
        // if (progress < 100) {
        //     setMessage('3초 동안 눌러주세요!');
        // }
    };

    const buttonStyle = {
        background: `linear-gradient(to right, #4f46e5 ${progress}%, #e5e7eb ${progress}%)`
    };

    return (
        <div className={styles.wrap}>
            <button
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                style={buttonStyle}
            >
                {text}
            </button>
        </div>
    );
}

export default AnButton;
